import React from 'react';
import PropTypes from 'prop-types';

import { THead, TableRow, TableHeaderCol } from '../../../Table';

import { Input } from '../../../Form';
import { getTranslatedText } from 'utils/common';

class DataListHeader extends React.PureComponent {
	state = {
		search: this.props.individualSearchFields || {},
	};

	static propTypes = {
		fields: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
				value: PropTypes.string,
				order: PropTypes.bool,
				hidden: PropTypes.bool,
			}),
		),
		currentOrder: PropTypes.string,
		onReorder: PropTypes.func,
		individualSearch: PropTypes.bool,
		individualSearchInactiveFieldsList: PropTypes.array,
	};

	static defaultProps = {
		fields: null,
		currentOrder: '',
		individualSearch: false,
		individualSearchInactiveFieldsList: [],
		onReorder: () => false,
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps && nextProps.individualSearchFields) {
			this.setState({ search: nextProps.individualSearchFields });
		}
	}

	getFieldSearch(fieldId) {
		return this.state.search[fieldId] || '';
	}

	onChangeFieldSearch = (fieldId) => (value) => {
		let search = this.state.search;
		search[fieldId] = value;
		this.setState({ search: { ...search } });
	};

	searchForData = () => {
		if (this.props.individualSearchCallback) {
			this.props.individualSearchCallback({ search: this.state.search });
		}
	};

	render() {
		let { individualSearch = false } = this.props;

		return (
			<THead>
				{individualSearch && (
					<TableRow>
						{this.props.fields &&
							this.props.fields
								.filter((th) => !th.hidden)
								.map((field, key) => (
									<th style={{ backgroundColor: '#f5f5f5' }} key={key}>
										{field.type !== 'checkbox' && this.props.individualSearchInactiveFieldsList.indexOf(field.id) === -1 && (
											<Input
												bordered
												placeholder={getTranslatedText("common.search", "Search")}
												value={this.getFieldSearch(field.id)}
												onChange={this.onChangeFieldSearch(field.id)}
												autoTrigger={{ cb: this.searchForData, length: 2, delay: 1000 }}
												style={{
													height: '30px',
													width: '90%',
													marginBottom: '5px',
													marginTop: '5px',
												}}
											/>
										)}
									</th>
								))}
					</TableRow>
				)}
				<TableRow>
					{this.props.fields &&
						this.props.fields
							.filter((th) => !th.hidden)
							.map((field, key) => (
								<TableHeaderCol
									key={key}
									field={field.value}
									align={field.align}
									hasOrder={field.order === undefined ? true : field.order}
									isActive={this.props.currentOrder === field.value}
									reOrder={this.props.onReorder}
								>
									{field.type === 'id' ? 'Id' : field.text}
								</TableHeaderCol>
							))}
				</TableRow>
			</THead>
		);
	}
}

export default DataListHeader;
