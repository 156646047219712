import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { List } from 'immutable';

import Table, { TableRow } from '../../Table';
import DataListHeader from './DataListHeader';
import Padding from '../../Padding';
import Flex, { Col } from '../../Flex';
import { Button, Select2 } from '../../Form';
import Pagination from 'react-paginating';
import styles from './component.module.scss';
import withQueryParams from '../../../components/Routing/withQueryParams';
import { getTranslatedText } from 'utils/common';

class DataList extends React.Component {
	static propTypes = {
		data: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
		pagination: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.shape({
				dataCount: PropTypes.number,
				urlSegment: PropTypes.string,
				onPaginate: PropTypes.func,
			}),
		]),
		fields: PropTypes.arrayOf(PropTypes.shape({})),
		isLoaded: PropTypes.bool,
		individualSearch: PropTypes.bool,
		loadingAsText: PropTypes.bool,
		showHeader: PropTypes.bool,
		noItems: PropTypes.string,
		queryParams: PropTypes.shape({
			filters: PropTypes.string,
		}).isRequired,
		match: PropTypes.shape({
			params: PropTypes.shape({
				page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			}),
		}).isRequired,
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
		rowRender: PropTypes.func.isRequired,
		onReorder: PropTypes.func,
		count: PropTypes.number,
		onPaginate: PropTypes.func,
		individualSearchCallBack: PropTypes.func,
		individualSearchInactiveFieldsList: PropTypes.array,
	};

	static defaultProps = {
		data: [],
		dataCount: 0,
		pagination: false,
		fields: [],
		showHeader: true,
		isLoaded: false,
		individualSearch: false,
		individualSearchInactiveFieldsList: [],
		loadingAsText: false,
		noItems: getTranslatedText("common.noItems", 'No Items'),
		onReorder: () => false,
	};

	state = {
		order: {
			key: 'createdAt',
			in: 'desc',
		},
		page: 0,
		currentPage: 1,
		limit: 50,
	};

	componentDidMount() {
		if (this.props.limit && typeof this.props.limit !== 'undefined' && this.props.limit !== null) {
			this.setState({ limit: this.props.limit });
		}
	}

	componentWillReceiveProps(nextProps) {
		if (typeof nextProps.limit !== 'undefined' && nextProps.limit !== null) {
			this.setState({ limit: nextProps.limit });
		}
	}

	get getView() {
		const { isLoaded, data, noItems, rowRender, fields, loadingAsText } = this.props;

		// todo : skip for now
		/* if (!isLoaded) {
			return (
				<TableRow>
					<td className="text-center pt-2" colSpan={fields.length}>
						Loading
					</td>
				</TableRow>
			);
		} else */
		if (data.count() === 0) {
			return (
				<TableRow>
					<td colSpan={fields.length}>{noItems}</td>
				</TableRow>
			);
		}
		return data.map(rowRender);
	}

	onReorder = (key, orderIn) => {
		let inOrder = orderIn;
		if (key === this.state.order.key) {
			inOrder = this.state.order.in === 'asc' ? 'desc' : 'asc';
		}
		this.setState(
			{
				order: {
					key,
					in: inOrder,
				},
			},
			() => {
				this.props.onReorder(this.state.order);
			},
		);
	};

	onPaginate = (page) => {
		this.setState({
			page,
		});
		this.props.onPaginate(page);
	};

	paginationRender = (arr) => {
		const { count } = this.props;

		return (
			<Padding padding={{ top: 30, bottom: 30 }}>
				<Flex xs={{ justify: 'center', align: 'center' }}>
					<Col xs={{ right: 20 }}>
						<div
							style={{
								cursor: 'pointer',
								height: 20,
								width: 130,
							}}
						>
							<Button
								primary={this.state.page !== 0}
								readonly={this.state.page === 0}
								onClick={() => {
									this.onPaginate(this.state.page - 1);
								}}
							>
								{getTranslatedText("table.previousPage", 'Previous Page')}
							</Button>
						</div>
					</Col>
					{arr.map((item, key) => (
						<Col key={key} xs={{ right: 10 }}>
							<div
								style={{
									cursor: 'pointer',
									width: 30,
									height: 20,
								}}
							>
								<Button
									primary={this.state.page !== item}
									readonly={this.state.page === item}
									onClick={() => {
										this.onPaginate(item);
									}}
								>
									{item + 1}
								</Button>
							</div>
						</Col>
					))}
					<Col xs={{ left: 10 }}>
						<div
							style={{
								cursor: 'pointer',
								height: 20,
								width: 130,
							}}
						>
							<Button
								primary={this.state.page !== Math.ceil(count / 10) - 1}
								readonly={this.state.page === Math.ceil(count / 10) - 1}
								onClick={() => {
									this.onPaginate(this.state.page + 1);
								}}
							>
								{getTranslatedText("table.nextPage", 'Next Page')}
							</Button>
						</div>
					</Col>
				</Flex>
			</Padding>
		);
	};

	handlePageChange = (page) => {
		this.setState(
			{
				currentPage: page,
			},
			() => {
				this.props.pagination.onPaginate(page, this.state.limit);
			},
		);
	};

	renderPagination() {
		const { pagination, data } = this.props;
		const pageCount = 5;

		if (data?.count() === 0 && pagination?.dataCount === 0) {
			return null;
		}
		return (
			<Pagination total={pagination.dataCount} limit={this.state.limit} pageCount={pageCount} currentPage={this.state.currentPage}>
				{({ pages, currentPage, hasNextPage, hasPreviousPage, previousPage, nextPage, totalPages, getPageItemProps }) => (
					<Padding padding={{ top: 20 }}>
						<Flex>
							<Col xs={{ size: 12 }} md={{ size: 9 }}>
								<Flex>
									<Col xs={{ size: 3, right: 10 }} sm={{ size: 2, right: 10 }} md={{ size: 2.5, right: 10 }}>
										<Select2
											field={{ name: 'limit' }}
											fixedPosition={false}
											options={[
												{ label: '5', value: 5 },
												{ label: '50', value: 50 },
												{ label: '100', value: 100 },
												{ label: '150', value: 150 },
												{ label: '200', value: 200 },
												{ label: '500', value: 500 },
											]}
											value={this.state.limit}
											onChange={(value) => {
												this.setState(
													{
														limit: value,
													},
													() => {
														this.handlePageChange(1);
													},
												);
											}}
										/>
									</Col>
									<Col xs={{ size: 1, right: 10 }}>
										<Button
											{...getPageItemProps({
												pageValue: 1,
												onPageChange: this.handlePageChange,
											})}
										>
											{getTranslatedText("common.first", 'First')}
										</Button>
									</Col>

									{hasPreviousPage && (
										<Col xs={{ size: 0.6, right: 5 }}>
											<Button
												{...getPageItemProps({
													pageValue: previousPage,
													onPageChange: this.handlePageChange,
												})}
											>
												{'<'}
											</Button>
										</Col>
									)}

									{pages.map((page) => (
										<Col key={page} xs={{ size: 0.8, left: 5, right: 5 }}>
											<Button
												primary={currentPage === page}
												{...getPageItemProps({
													pageValue: page,
													onPageChange: this.handlePageChange,
												})}
											>
												{page}
											</Button>
										</Col>
									))}

									{hasNextPage && (
										<Col xs={{ size: 0.8, left: 5, right: 10 }}>
											<Button
												{...getPageItemProps({
													pageValue: nextPage,
													onPageChange: this.handlePageChange,
												})}
											>
												{'>'}
											</Button>
										</Col>
									)}

									<Col xs={{ size: 1, right: 10, left: 10 }}>
										<Button
											{...getPageItemProps({
												pageValue: totalPages,
												onPageChange: this.handlePageChange,
											})}
										>
											{getTranslatedText("common.last", 'Last')}
										</Button>
									</Col>
								</Flex>
							</Col>
							<Col xs={{ size: 12 }} md={{ size: 3 }}>
								<p style={{ textAlign: 'right' }}>
									{getTranslatedText("common.showing", 'Showing')} {(this.state.currentPage - 1) * this.state.limit + 1} to{' '}
									{this.state.currentPage * this.state.limit > pagination.dataCount ? pagination.dataCount : this.state.currentPage * this.state.limit} of{' '}
									{pagination.dataCount}
								</p>
							</Col>
						</Flex>
					</Padding>
				)}
			</Pagination>
		);
	}

	render() {
		const { pagination, fields, count, showHeader = true, individualSearch = false } = this.props;
		const arr = [];

		if (count % 10 === 0) {
			for (let i = 0; i < count / 10; i++) {
				arr[i] = i;
			}
		} else {
			for (let i = 0; i < Math.ceil(count / 10); i++) {
				arr[i] = i;
			}
		}

		const includePagination = pagination !== false;

		return (
			<div style={{ width: '100%' }}>
				<div style={{ width: '100%', overflowY: 'auto', overflowX: 'scroll' }}>
					<Table>
						{showHeader && (
							<DataListHeader
								fields={fields}
								currentOrder={this.state.order.key}
								onReorder={this.onReorder}
								individualSearch={individualSearch}
								individualSearchInactiveFieldsList={this.props.individualSearchInactiveFieldsList}
								individualSearchFields={this.props.individualSearchFields}
								individualSearchCallback={this.props.individualSearchCallback}
							/>
						)}
						<tbody>{this.getView}</tbody>
					</Table>
				</div>
				<div>
					{includePagination && this.renderPagination()}
					{count > 10 ? this.paginationRender(arr) : null}
				</div>
			</div>
		);
	}
}

export default withRouter(withQueryParams(DataList));
